<template>
  <template v-for="(block, i) in blocks" :key="i">
    <cms-breadcrumb
      v-if="block.__component === 'elemente.breadcrumb'"
      :class="block.dense ? 'mb-4' : 'mb-10'"
    />

    <cms-consent-map
      v-if="block.__component === 'elemente.map'"
      v-bind="block"
    />

    <cms-image-slider
      v-if="block.__component === 'elemente.slider'"
      v-bind="block"
    />

    <v-container
      v-if="block.__component === 'elemente.main-heading'"
      :fluid="block.fluid"
      class="py-0"
    >
      <cms-main-heading
        :text="block.text"
        :primary="block.primaryColor"
      />
    </v-container>

    <v-container
      v-if="block.__component === 'elemente.card-grid'"
      :fluid="block.fluid"
      :class="{
        'pa-0': block.fluid,
        'py-0 mb-15': !block.fluid,
      }"
    >
      <cms-card-grid
        :cards="block.imageCards"
        :is-gallery="block.gallery"
        :align="block.align"
      />
    </v-container>

    <cms-content-card
      v-if="block.__component === 'elemente.content-card'"
      v-bind="block"
    />

    <cms-simple-image
      v-if="block.__component === 'elemente.simple-image'"
      v-bind="block"
    />

    <cms-simple-content
      v-if="block.__component === 'elemente.simple-content'"
      v-bind="block"
    />

    <cms-expansion-panel
      v-if="block.__component === 'elemente.expansion-panel'"
      v-bind="block"
    />

    <template v-if="block.__component === 'elemente.form'">
      <v-container class="py-0 mb-15">
        <cms-form-builder v-bind="block" />
      </v-container>
    </template>
  </template>
</template>

<script>
export default {
  name: 'block-renderer',

  props: {
    blocks: {
      type: Array,
      default: () => ([]),
    },
  },
}
</script>
