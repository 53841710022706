<template>
  <div class="cms-image-slider--wrap">
    <v-container
      :fluid="fluid"
      class="py-0"
      :class="{
        'pa-0': fluid,
        'mb-15': addVerticalSpace,
      }"
    >
      <v-sheet
        :height="height"
        class="slider--wrap"
        :class="{
          'rounded': !fluid,
        }"
      >
        <swiper-container ref="swiper" :init="false">
          <swiper-slide v-for="(slide, i) in slides" :key="i">
            <v-sheet :height="height">
              <cms-image
                :image-data="slide.image"
                :alt-fallback="slide.title"
                :loading="i === 0 ? 'eager' : 'lazy'"
                :height="height"
                cover
              />

              <v-row v-if="withContent" class="content-row" no-gutters>
                <v-col
                  :cols="12"
                  :md="6"
                  :offset-md="slide.contentRight ? 6 : 0"
                  class="position-relative"
                >
                  <!-- z-index 1 below fixed badges at the side, 2 below toolbar/app-header -->
                  <v-overlay
                    v-if="withContent"
                    :model-value="true"
                    class="align-center justify-center ma-1"
                    contained
                    scrim="#fff"
                    opacity="0.66"
                    z-index="1002"
                  >
                    <div class="pa-8">
                      <div v-if="slide.title" class="text-h4 font-weight-medium mb-2 text-primary">
                        {{ slide.title }}
                      </div>
                      <div v-if="slide.text" class="text-h6">
                        {{ slide.text }}
                      </div>
                    </div>
                  </v-overlay>
                </v-col>
              </v-row>
            </v-sheet>
          </swiper-slide>
        </swiper-container>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'image-slider',

  props: {
    // dots indicating current slide, slide-amount
    bullets: {
      type: Boolean,
      default: false,
    },

    // uses a fade-effect for slide-transition instead of a overlap-variant
    fade: {
      type: Boolean,
      default: false,
    },

    // animated slide-progress (alternative to bullets)
    scrollbar: {
      type: Boolean,
      default: false,
    },

    // uses a container if unset
    fluid: {
      type: Boolean,
      default: false,
    },

    // height of the wraps
    height: {
      type: Number,
      default: 420,
    },

    // { image, title, text }
    slides: {
      type: Array,
      default: () => ([])
    },

    // shows title, text of the slides as overlay
    withContent: {
      type: Boolean,
      default: false,
    },

    // adds a margin-bottom
    addVerticalSpace: {
      type: Boolean,
      default: false,
    },
  },

  mounted () {
    this.initSwiper()
  },

  methods: {
    initSwiper () {
      const options = {
        slidesPerView: 1,
        loop: true,
        effect: this.fade ? 'fade' : 'creative',
        navigation: false,
        pagination: this.bullets,
        scrollbar: this.scrollbar,
        autoplay: {
          delay: 8000,
          disableOnInteraction: true,
        },
        injectStyles: [
          `
          .swiper-pagination-bullet {
            border: 1px solid rgba(255,255,255,0.85);
          }
          `,
        ],
      }

      if (!this.fade) {
        options.creativeEffect = {
          prev: {
            shadow: false,
            translate: ['-20%', 0, -1],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        }
      }

      Object.assign(this.$refs.swiper, options)
      this.$refs.swiper.initialize()
    },
  },
}
</script>

<style lang="scss">
  .cms-image-slider--wrap {
    .slider--wrap {
      overflow: hidden;

      picture {
        display: inline-flex;
        width: 100%;
        height: 100%;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .content-row {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: 0;

        .v-overlay {
          backdrop-filter: blur(3px);
        }
      }
    }
  }
</style>
