<template>
  <div v-if="text" class="cms-main-heading--wrap d-inline-block mb-10">
    <h2
      class="text-h4 font-weight-medium"
      :class="{
        'text-primary': primary
      }"
    >
      {{ text }}
    </h2>
  </div>
</template>

<script>
export default {
  name: 'cms-main-heading',

  props: {
    text: {
      type: String,
      default: '',
    },
    primary: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
