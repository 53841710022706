<template>
  <div class="cms-simple-content--wrap mb-15">
    <v-container
      class="py-0"
      :fluid="fluid"
      :class="{
        'pa-0': fluid
      }"
    >
      <cms-html-content
        :html="text"
        :class="{ 'text-h6 font-weight-regular': largerText }"
      />
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'cms-simple-content',

  props: {
    fluid: {
      type: Boolean,
      default: false,
    },

    text: {
      type: String,
      default: '',
    },

    largerText: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
