<script setup>
import config from '@/preload/data/config.json'
import navEntries from '@/preload/data/categories.json'

const route = useRoute()

function getTreeItemByPath (tree = [], path = '') {
  const result = []

  function search (node) {
    if (node.path === path) {
      result.push({ title: node.title, href: node.path })
      return true
    }

    for (const child of node.children || []) {
      if (search(child)) {
        result.unshift({ title: node.title, href: '' })
        return true
      }
    }

    return false
  }

  for (const rootNode of tree) {
    search(rootNode)
  }

  return result
}

const items = [
  { title: config?.name, href: '/' },
  ...getTreeItemByPath(navEntries, route.path)
]
</script>

<template>
  <v-container class="cms-breadcrumb--wrap py-0">
    <v-breadcrumbs
      :items="items"
      class="pa-0 ma-0 ml-n1 d-none d-sm-block"
    />
  </v-container>
</template>
