<template>
  <div class="form-builder--wrap">
    <v-form
      v-if="!formSent"
      v-model="formIsValid"
      @submit.prevent="submitForm()"
    >
      <div v-for="(field, i) in fields" :key="i">
        <v-text-field
          v-if="field.type === 'textfields'"
          v-model="field.value"
          variant="outlined"
          :type="getTextFieldType(field)"
          :label="getLabel(field)"
          :rules="getTextFieldRules(field)"
          class="mb-4"
          hide-details="auto"
        />

        <v-textarea
          v-if="field.type === 'textareas'"
          v-model="field.value"
          variant="outlined"
          :label="getLabel(field)"
          :rules="field.required ? needsValueRules : []"
          class="mb-4"
          hide-details="auto"
        />

        <v-checkbox
          v-if="field.type === 'checkboxes'"
          v-model="field.checked"
          :rules="field.required ? needsValueRules : []"
          class="mb-4 ml-2"
          hide-details="auto"
          density="comfortable"
        >
          <template #label>
            <cms-html-content :html="field.label" />
          </template>
        </v-checkbox>

        <div
          v-if="field.type === 'checkboxgroups'"
          class="my-6"
        >
          <label v-if="field.label" class="mb-2 ml-4 v-label">
            {{ getLabel(field) }}
          </label>
          <v-checkbox
            v-for="(checkbox, x) in field.checkboxes"
            :key="`${i}_${x}`"
            v-model="field.checked"
            :value="checkbox.name"
            :label="getLabel(checkbox)"
            :rules="getCheckboxGroupRules(field)"
            :hide-details="x === field.checkboxes.length - 1 ? 'auto': true"
            density="compact"
            class="ml-4"
          />
        </div>

        <v-radio-group
          v-if="field.type === 'radiogroups'"
          v-model="field.value"
          :label="getLabel(field)"
          :rules="field.required ? needsValueRules : []"
          class="mb-4"
          hide-details="auto"
        >
          <v-radio
            v-for="(radio, x) in field.radios"
            :key="`${i}_${x}`"
            :label="radio.label"
            :value="radio.value"
          />
        </v-radio-group>
      </div>

      <div class="honey">
        <input
          ref="honey"
          type="text"
          name="not_a_password"
          tabindex="-1"
          autocomplete="off"
        >
      </div>

      <div class="d-flex mt-10">
        <v-btn
          :loading="postInProgress"
          type="submit"
          flat
          :color="actionColor"
          :class="{
            'ml-auto': actionRight,
            'text-primary': actionColor !== 'primary'
          }"
          size="large"
          :disabled="!formIsValid"
        >
          {{ actionLabel }}
        </v-btn>
      </div>
    </v-form>

    <v-alert
      v-if="formSent"
      color="success"
      rounded="0"
      class="pa-6"
    >
      <v-icon icon="$check" start />

      {{ successMessage }}
    </v-alert>

    <v-snackbar
      v-model="showSuccessSnackbar"
      :timeout="snackbarTimeout"
      color="success"
    >
      <div class="text-body-1">
        {{ successMessage }}
      </div>
    </v-snackbar>

    <v-snackbar
      v-model="showErrorSnackbar"
      :timeout="snackbarTimeout"
      color="error"
    >
      <div class="text-body-1">
        {{ errorMessage }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'form-builder',

  props: {
    actionRight: {
      type: Boolean,
      default: true,
    },

    actionColor: {
      type: String,
      default: 'primary',
    },

    actionLabel: {
      type: String,
      default: 'Absenden',
    },

    name: {
      type: String,
      default: '',
    },

    successMessage: {
      type: String,
      default: 'Das Formular wurde erfolgreich verschickt.',
    },

    errorMessage: {
      type: String,
      default: 'Beim Verschicken ist leider ein Fehler aufgetreten.',
    },

    textfields: {
      type: Array,
      default: () => ([]),
    },

    textareas: {
      type: Array,
      default: () => ([]),
    },

    radiogroups: {
      type: Array,
      default: () => ([]),
    },

    checkboxes: {
      type: Array,
      default: () => ([]),
    },

    checkboxgroups: {
      type: Array,
      default: () => ([]),
    },
  },

  data () {
    return {
      formIsValid: null,
      formSent: false,
      fields: [],
      snackbarTimeout: 5000,
      showErrorSnackbar: false,
      showSuccessSnackbar: false,
      postInProgress: false,
      mountedAt: null,
    }
  },

  computed: {
    needsValueRules () {
      return [value => !!value || 'Dies ist ein Pflichtfeld']
    },

    emailRules () {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      return [value => emailRegex.test(value) || 'Bitte geben Sie eine gültige E-Mail-Adresse ein']
    },
  },

  mounted () {
    this.mountedAt = new Date().getTime()
    this.formSent = false
    this.createFieldList()
  },

  methods: {
    /**
     * Returns the label of the given field. If it is required, we add an
     * indicator.
     *
     * @returns {string}
     */
    getLabel (field) {
      return field.label
        ? `${field.label}${field.required ? '*' : ''}`
        : ''
    },

    /**
     * If a checkbox-group is required, atleast one checkbox has to be set.
     *
     * @param {object} field
     * @returns {array}
     */
    getCheckboxGroupRules (field) {
      return field.required
        ? [field.checked.length > 0 || 'Bitte wählen Sie mindestens eine Option aus']
        : []
    },

    /**
     * getTextFieldRules
     *
     * @param {object}
     * @returns {string}
     */
    getTextFieldRules (field) {
      let rules = []

      if (field.required) {
        rules = [...rules, ...this.needsValueRules]
      }

      if (field.isEmail) {
        rules = [...rules, ...this.emailRules]
      }

      return rules
    },

    /**
     * getTextFieldType
     *
     * @param {object}
     * @returns {string}
     */
    getTextFieldType (field) {
      if (field.isEmail) {
        return 'email'
      }

      if (field.isNumber) {
        return 'number'
      }

      return 'text'
    },

    /**
     * Transforms the form-datastructure coming from our cms to a flat, sorted
     * list of fields with a type so we can render fitting elements.
     *
     * @returns {undefined}
     */
    createFieldList () {
      this.fields = [
        ...this.textfields.map(def => ({ ...def, type: 'textfields' })),
        ...this.textareas.map(def => ({ ...def, type: 'textareas' })),
        ...this.radiogroups.map(def => ({ ...def, type: 'radiogroups' })),
        ...this.checkboxes.map(def => ({ ...def, type: 'checkboxes' })),
        ...this.checkboxgroups.map(def => ({ ...def, type: 'checkboxgroups', checked: [] })),
      ].sort((a, b) => (a.position || 0) - (b.position || 0))
    },

    /**
     * Transforms the fields and the entered values of this into a readable
     * format that can get send to our cms.
     *
     * @returns {string}
     */
    fieldsToContent () {
      const getFieldValue = field => {
        if (field.type === 'checkboxes') {
          return field.checked
        }

        if (field.type === 'checkboxgroups') {
          return field.checked.join(', ')
        }

        return field.value || 'Keine Angabe'
      }

      return this.fields
        .map(field => `${field.name}:\n${getFieldValue(field)}`)
        .join('\n\n')
    },

    async submitForm () {
      const tooFast = (new Date().getTime() - this.mountedAt) / 1000 < 5
      const honey = this.$refs.honey?.value

      if (!this.formIsValid || honey || tooFast) {
        return
      }

      this.showErrorSnackbar = false
      this.showSuccessSnackbar = false
      this.postInProgress = true

      const res = await fetch(`${this.$config.public.cmsUrl}/api/forms`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            name: this.name,
            content: this.fieldsToContent()
          },
        })
      })

      this.postInProgress = false

      if (res.ok) {
        this.formSent = true
        this.showSuccessSnackbar = true
        return
      }

      this.showErrorSnackbar = true
    },
  },
}
</script>

<style lang="scss">
  .form-builder--wrap {
    .honey {
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }
  }
</style>
