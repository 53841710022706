<template>
  <div
    class="cms-simple-image--wrap"
    :class="{
      'd-md-none': !showDesktop,
      'd-none d-md-block': !showMobile,
      'mb-15': addVerticalSpace,
    }"
  >
    <v-container
      class="py-0"
      :fluid="fluid"
      :class="{
        'pa-0': fluid
      }"
    >
      <cms-image
        :image-data="image"
        :cover="cover"
        :width="width"
        :height="height"
        :class="{
          'mx-auto': centered,
        }"
      />
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'cms-simple-image',

  props: {
    cover: {
      type: Boolean,
      default: false,
    },

    fluid: {
      type: Boolean,
      default: false,
    },

    // object from strapi containing image-data
    image: {
      type: Object,
      required: true,
    },

    // adds a bottom-margin
    addVerticalSpace: {
      type: Boolean,
      default: false,
    },

    centered: {
      type: Boolean,
      default: false,
    },

    visibility: {
      type: String,
      default: 'both',
      validator (value) {
        return ['both', 'desktop', 'mobile'].includes(value)
      },
    },

    width: {
      type: Number,
      default: null,
    },

    height: {
      type: Number,
      default: null,
    },
  },

  computed: {
    showDesktop () {
      return this.visibility === 'both' || this.visibility === 'desktop'
    },

    showMobile () {
      return this.visibility === 'both' || this.visibility === 'mobile'
    },
  },
}
</script>
