<template>
  <div v-if="items.length > 0" class="cms-expansion-panel--wrap">
    <v-container class="py-0 mb-15">
      <v-expansion-panels
        :variant="variant"
        :multiple="multiple"
      >
        <v-expansion-panel
          v-for="(item, i) in items"
          :key="i"
          :title="item.title"
        >
          <v-expansion-panel-text>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="item.content" />
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'cms-expansion-panel',

  props: {
    items: {
      type: Array,
      default: () => ([]),
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    variant: {
      type: String,
      default: 'default',
      validator (value) {
        return ['default', 'accordion', 'inset', 'popout'].includes(value)
      },
    },
  },
}
</script>

<style lang="scss">
  .cms-expansion-panel--wrap {
    .v-expansion-panel {
      &.v-expansion-panel--active {
        .v-expansion-panel-title {
          font-weight: 500;

          .v-expansion-panel-title__overlay {
            opacity: calc(var(--v-hover-opacity)* var(--v-theme-overlay-multiplier));
          }
        }
      }
    }
  }
</style>
