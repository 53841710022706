<template>
  <v-container
    v-if="mapConfig && active"
    class="consent-map--wrap position-relative py-0"
    :fluid="fluid"
    :class="{
      'mb-15': !fluid,
      'pa-0': fluid
    }"
  >
    <cms-image
      v-if="!consentGiven"
      :image-data="mapConfig.image"
      :height="height"
      cover
    />

    <iframe
      v-if="consentGiven"
      :src="mapConfig.link"
      :height="height"
      width="100%"
      frameborder="0"
      allowfullscreen
    />

    <!-- z-index 1 below fixed badges at the side, 2 below toolbar/app-header -->
    <v-overlay
      :model-value="!consentGiven"
      class="map-content-overlay align-center justify-center"
      contained
      scrim="#fff"
      opacity="0.5"
      z-index="1002"
      persistent
    >
      <v-card
        width="600"
        max-width="95%"
        elevation="15"
        class="pa-2 ma-auto"
        rounded="0"
      >
        <v-card-title>
          Inhalt einblenden
        </v-card-title>
        <v-card-text>
          <p>
            Wenn Sie die Google-Maps Kartenfunktion aktivieren, werden personenbezogene Daten an Google übermittelt.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn
            color="primary"
            variant="elevated"
            flat
            @click="consentGiven = true"
          >
            Einwilligen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
import mapConfig from '@/preload/data/map.json'

export default {
  name: 'consent-map',

  props: {
    active: {
      type: Boolean,
      default: true,
    },

    fluid: {
      type: Boolean,
      default: true,
    },

    height: {
      type: Number,
      default: 500,
    },
  },

  data () {
    return {
      mapConfig,
      consentGiven: false,
    }
  },
}
</script>

<style lang="scss">
  .consent-map--wrap {
    iframe {
      display: block;
    }

    .map-content-overlay {
      .v-overlay__content {
        max-width: 100%;
      }
    }
  }
</style>
